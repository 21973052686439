body {
  background-color: #000F1A;
  color: rgba(255, 255, 255, 0.75) !important;
}

.h1, .h2, .h3, .h4 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.modal {
  color: #181818 !important;
}

.demist-label,
.submit {
  background-color: #00eB9a !important;
  color: #181818 !important;
}

.demist-label {
  margin-bottom: 12px !important;
}

.submit:hover {
  background-color: #00cc85 !important;
}

.demisto-note {
  color: white;
  font-weight: 300;
  padding-bottom: 20px;
  margin: 20px auto !important;
}